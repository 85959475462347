import React, { useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination
} from "@mui/material";
import axios from "axios";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20); // 20 appointments per page

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem("adminAuthToken"); // Assuming token is stored in localStorage
      const response = await axios.get("https://d18ye91bhcj72x.cloudfront.net/api/admin/appointments", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAppointments(response.data);
    } catch (error) {
      console.error("Error fetching appointments", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <h3>Appointments</h3>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User A</TableCell>
              <TableCell>User B</TableCell>
              <TableCell>Email A</TableCell>
              <TableCell>Email B</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time Slot</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Meeting Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {appointments
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((appt) => (
      <TableRow key={appt._id}>
        <TableCell>
          {appt.userA ? `${appt.userA.firstName} ${appt.userA.lastName}` : "N/A"}
        </TableCell>
        <TableCell>
          {appt.userB ? `${appt.userB.firstName} ${appt.userB.lastName}` : "N/A"}
        </TableCell>
        <TableCell>{appt.userAEmail || "N/A"}</TableCell>
        <TableCell>{appt.userBEmail || "N/A"}</TableCell>
        <TableCell>{appt.date ? new Date(appt.date).toLocaleDateString() : "N/A"}</TableCell>
        <TableCell>{appt.timeSlot || "N/A"}</TableCell>
        <TableCell>{appt.status || "N/A"}</TableCell>
        <TableCell>
          {appt.meetingLink ? (
            <a href={appt.meetingLink} target="_blank" rel="noopener noreferrer">
              Join Meeting
            </a>
          ) : "N/A"}
        </TableCell>
      </TableRow>
    ))}
</TableBody>

        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={appointments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[20]} // Fixed at 20 per page
      />
    </div>
  );
};

export default Appointments;
