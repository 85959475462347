
// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Tabs,
//   Tab,
//   Paper,
//   Typography,
//   Grid2,
//   Box,
//   CircularProgress,
//   Card,
//   CardContent,
// } from "@mui/material";
// import UsersTable from "./UserTable";
// import Appointments from "./Appointments";
// import Navbar from "./components/Navbar";

// const AdminDashboard = () => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [stats, setStats] = useState({
//     totalUsers: 0,
//     verifiedUsers: 0,
//     unverifiedUsers: 0,
//     totalAppointments: 0,
//     loading: true,
//     error: null,
//   });

//   useEffect(() => {
//     const fetchStats = async () => {
//       try {
//         const token = localStorage.getItem("adminAuthToken"); // Retrieve Bearer token
//         const headers = { Authorization: `Bearer ${token}` };

//         // Fetch all required stats in parallel
//         const [usersRes, appointmentsRes, verificationRes] = await Promise.all([
//           fetch("http://localhost:5000/api/admin/total-users", { headers }),
//           fetch("http://localhost:5000/api/admin/total-appointments", { headers }),
//           fetch("http://localhost:5000/api/admin/user-verification", { headers }),
//         ]);

//         if (!usersRes.ok || !appointmentsRes.ok || !verificationRes.ok) {
//           throw new Error("Failed to fetch stats");
//         }

//         // Convert responses to JSON
//         const [usersData, appointmentsData, verificationData] = await Promise.all([
//           usersRes.json(),
//           appointmentsRes.json(),
//           verificationRes.json(),
//         ]);

//         setStats({
//           totalUsers: usersData.totalUsers || 0,
//           verifiedUsers: verificationData.verifiedUsers || 0,
//           unverifiedUsers: verificationData.unverifiedUsers || 0,
//           totalAppointments: appointmentsData.totalAppointments || 0,
//           loading: false,
//           error: null,
//         });
//       } catch (error) {
//         setStats((prev) => ({ ...prev, loading: false, error: "Failed to load stats" }));
//       }
//     };

//     fetchStats();
//   }, []);

//   return (
//     <Box sx={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }}>
//       {/* Navbar */}
//       <Navbar />

//       {/* Dashboard Content */}
//       <Container maxWidth={false} sx={{ mt: 4, px: 3 }}>
//         <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}>
//           Admin Dashboard
//         </Typography>

//         {/* Stats Section */}
//         {stats.loading ? (
//           <Box sx={{ textAlign: "center", my: 4 }}>
//             <CircularProgress />
//           </Box>
//         ) : stats.error ? (
//           <Typography color="error" sx={{ textAlign: "center", my: 4 }}>
//             {stats.error}
//           </Typography>
//         ) : (
//           <>
//             {/* User Statistics */}
//             <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
//               <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
//                 User Statistics
//               </Typography>
//               <Grid2 container spacing={3}>
//                 <Grid2 item xs={12} sm={4}>
//                   <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
//                     <CardContent>
//                       <Typography variant="h6" color="gray">
//                         Total Users
//                       </Typography>
//                       <Typography variant="h4" sx={{ fontWeight: "bold", color: "primary.main" }}>
//                         {stats.totalUsers}
//                       </Typography>
//                     </CardContent>
//                   </Card>
//                 </Grid2>
//                 <Grid2 item xs={12} sm={4}>
//                   <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
//                     <CardContent>
//                       <Typography variant="h6" color="gray">
//                         Verified Users
//                       </Typography>
//                       <Typography variant="h4" sx={{ fontWeight: "bold", color: "primary.main" }}>
//                         {stats.verifiedUsers}
//                       </Typography>
//                     </CardContent>
//                   </Card>
//                 </Grid2>
//                 <Grid2 item xs={12} sm={4}>
//                   <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
//                     <CardContent>
//                       <Typography variant="h6" color="gray">
//                         Unverified Users
//                       </Typography>
//                       <Typography variant="h4" sx={{ fontWeight: "bold", color: "primary.main" }}>
//                         {stats.unverifiedUsers}
//                       </Typography>
//                     </CardContent>
//                   </Card>
//                 </Grid2>
//               </Grid2>
//             </Paper>

//             {/* Appointments Statistics */}
//             <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
//               <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
//                 Appointments Statistics
//               </Typography>
//               <Grid2 container spacing={3}>
//                 <Grid2 item xs={12} sm={6}>
//                   <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
//                     <CardContent>
//                       <Typography variant="h6" color="gray">
//                         Total Appointments
//                       </Typography>
//                       <Typography variant="h4" sx={{ fontWeight: "bold", color: "primary.main" }}>
//                         {stats.totalAppointments}
//                       </Typography>
//                     </CardContent>
//                   </Card>
//                 </Grid2>
//               </Grid2>
//             </Paper>
//           </>
//         )}

//         {/* Users & Appointments Section */}
// <Paper elevation={3} sx={{ p: 3 }}>
//   {/* Center the Tabs */}
//   <Box sx={{ display: "flex", justifyContent: "center" }}>
//     <Tabs
//       value={activeTab}
//       onChange={(e, val) => setActiveTab(val)}
//       indicatorColor="primary"
//       textColor="primary"
//     >
//       <Tab label="Users" />
//       <Tab label="Appointments" />
//     </Tabs>
//   </Box>

//   {/* Content below the tabs */}
//   <Box sx={{ mt: 2 }}>
//     {activeTab === 0 ? <UsersTable /> : <Appointments />}
//   </Box>
// </Paper>

//       </Container>
//     </Box>
//   );
// };

// export default AdminDashboard;






import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Tabs,
  Tab,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Skeleton,
  Grid,
  Fade,
  
} from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
import UsersTable from "./UserTable";
import Appointments from "./components/Appointments";
import Navbar from "./components/Navbar";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [stats, setStats] = useState({
    totalUsers: 0,
    verifiedUsers: 0,
    unverifiedUsers: 0,
    totalAppointments: 0,
    loading: true,
    error: null,
  });

  // Fetch Stats - Memoized to prevent unnecessary re-renders
  const fetchStats = useCallback(async () => {
    try {
      const token = localStorage.getItem("adminAuthToken"); // Retrieve Bearer token
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch all required stats in parallel
      const [usersRes, appointmentsRes, verificationRes] = await Promise.all([
        fetch("https://d18ye91bhcj72x.cloudfront.net/api/admin/total-users", { headers }),
        fetch("https://d18ye91bhcj72x.cloudfront.net/api/admin/total-appointments", { headers }),
        fetch("https://d18ye91bhcj72x.cloudfront.net/api/admin/user-verification", { headers }),
      ]);

      if (!usersRes.ok || !appointmentsRes.ok || !verificationRes.ok) {
        throw new Error("Failed to fetch stats");
      }

      // Convert responses to JSON
      const [usersData, appointmentsData, verificationData] = await Promise.all([
        usersRes.json(),
        appointmentsRes.json(),
        verificationRes.json(),
      ]);

      setStats({
        totalUsers: usersData.totalUsers || 0,
        verifiedUsers: verificationData.verifiedUsers || 0,
        unverifiedUsers: verificationData.unverifiedUsers || 0,
        totalAppointments: appointmentsData.totalAppointments || 0,
        loading: false,
        error: null,
      });
    } catch (error) {
      setStats((prev) => ({ ...prev, loading: false, error: "Failed to load stats" }));
    }
  }, []);

  useEffect(() => {
    fetchStats(); // Initial fetch

    const interval = setInterval(fetchStats, 10000); // Auto-refresh stats every 10s

    return () => clearInterval(interval); // Cleanup
  }, [fetchStats]);

  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }}>
      <Navbar />

      <Container maxWidth={false} sx={{ mt: 4, px: 3 }}>
        <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}>
          Admin Dashboard
        </Typography>

        {/* Stats Section */}
        {stats.loading ? (
          <Grid container spacing={3}>
            {[1, 2, 3, 4].map((index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Skeleton variant="text" width="60%" height={30} />
                    <Skeleton variant="rectangular" width="100%" height={50} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : stats.error ? (
          <Box textAlign="center" my={4}>
            <Typography color="error">{stats.error}</Typography>
            <Button variant="contained" color="primary" onClick={fetchStats} sx={{ mt: 2 }}>
              Retry
            </Button>
          </Box>
        ) : (
          <>
            {/* User Statistics */}
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                User Statistics
              </Typography>
              <Grid container spacing={3}>
                {[
                  { label: "Total Users", value: stats.totalUsers, tab: 0 },
                  { label: "Verified Users", value: stats.verifiedUsers, tab: 0 },
                  { label: "Unverified Users", value: stats.unverifiedUsers, tab: 0 },
                ].map((item, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Card
                      sx={{
                        textAlign: "center",
                        p: 2,
                        boxShadow: 3,
                        cursor: "pointer",
                        transition: "0.3s",
                        "&:hover": { boxShadow: 6 },
                      }}
                      onClick={() => setActiveTab(item.tab)}
                    >
                      <CardContent>
                        <Typography variant="h6" color="gray">
                          {item.label}
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: "bold", color: "primary.main" }}>
                          {item.value}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>

            {/* Appointments Statistics */}
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Appointments Statistics
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
                    <CardContent>
                      <Typography variant="h6" color="gray">
                        Total Appointments
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold", color: "primary.main" }}>
                        {stats.totalAppointments}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}

        {/* Users & Appointments Section */}
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Tabs
              value={activeTab}
              onChange={(e, val) => setActiveTab(val)}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "& .MuiTab-root": { fontWeight: "bold", textTransform: "none", fontSize: "1.1rem" },
                "& .MuiTabs-indicator": { backgroundColor: "#1976d2", height: 4, borderRadius: 2 },
              }}
            >
              <Tab label="Users" />
              <Tab label="Appointments" />
            </Tabs>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Fade in={activeTab === 0} timeout={500}>
              <div style={{ display: activeTab === 0 ? "block" : "none" }}>
                <UsersTable />
              </div>
            </Fade>
            <Fade in={activeTab === 1} timeout={500}>
              <div style={{ display: activeTab === 1 ? "block" : "none" }}>
                <Appointments />
              </div>
            </Fade>
          </Box>
        </Paper>
      </Container>

      {/* Floating Action Button for Quick Actions */}
      {/* <Fab color="primary" sx={{ position: "fixed", bottom: 16, right: 16 }}>
        <AddIcon />
      </Fab> */}
    </Box>
  );
};

export default AdminDashboard;


