import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./pages/Register"; 
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CompleteProfile from "./pages/CompleteProfile"; 
// import VerifyEmail from "./pages/VerifyEmail";
import { Box } from "@mui/material";
import ProfilePage from "./pages/ProfilePage";
import Appointments from "./pages/Appointments";
import ProfileSettings from "./pages/ProfileSettings";
import Bootcamp from "./pages/Bootcamp";
import ForgotPassword from "./pages/ForgotPassword";
import BootCampDay2 from "./pages/BootCamp_Days/BootCampDay2";
import BootcampDay3 from './pages/BootCamp_Days/BootcampDay3'
import BootCampDay4 from "./pages/BootCamp_Days/BootCampDay4";
import BootCampDay1 from "./pages/BootCamp_Days/BootcampDay1";
import ResetPassword from "./pages/ResetPassword";
import BootCampDay5 from "./pages/BootCamp_Days/BootCampDay5";
//Admin
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminDashboard from "./pages/Admin/AdminDashboard";

const App = () => {
  return (
    <Router>
      <Box
        sx={{ backgroundColor: "#51ade5" }}
        display="flex"
        maxWidth='100vw'
        minHeight="100vh"
        alignItems="center"
        justifyContent="center"
        margin='0'
      >
        <Routes>
          <Route path="/" element={<Register />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/verify-email" element={<VerifyEmail />} /> */}
         <Route path="/complete-profile" element={<CompleteProfile />} /> 
        {/* <Route path="/complete-profile" element={<CompleteProfile />} /> */}
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path='/get-profile' element={<ProfilePage />} />
          <Route path="/e-meets" element={<Appointments />} />
          <Route path='/update-profile' element={<ProfileSettings />} />
          <Route path='/bootcamp' element={<Bootcamp />} />
          <Route path='/bootcamp/day1' element={<BootCampDay1 />} />
          <Route path='/bootcamp/day2' element={<BootCampDay2 />} />
          <Route path='/bootcamp/day3' element={<BootcampDay3 />} />
          <Route path='/bootcamp/day4' element={<BootCampDay4 />} />
          <Route path='/bootcamp/day5' element={<BootCampDay5 />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/get-profile/:userId' element={<ProfilePage />} />
          {/* Admin Routes */}
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />


          {/* Add more routes here as needed */}
        </Routes>
      </Box>
    </Router>
  );
};

export default App;
