import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Alert
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  CheckCircle,
  Cancel,
  Google,
  GitHub,
  Apple,
  LinkedIn
} from "@mui/icons-material";
import axios from "axios";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Register = () => {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  // Password strength calculation
  const hasMinLength = password.length >= 8;
  const hasSymbolOrNumber = /[0-9!@#$%^&*(),.?":{}|<>]/.test(password);
  const doesNotContainName = !/john|doe/i.test(password);
  
  const strength = !password.length 
    ? 0 
    : hasMinLength && hasSymbolOrNumber && doesNotContainName 
      ? 3 
      : hasMinLength && (hasSymbolOrNumber || doesNotContainName) 
        ? 2 
        : 1;

  // Wave animation effect
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const waves = {
      y: canvas.height / 2,
      length: 0.01,
      amplitude: 100,
      frequency: 0.007
    };

    const strokeColor = {
      h: 210, // Adjusted to match #51ade5
      s: 100,  // Adjusted saturation
      l: 100,  // Adjusted lightness
      a: 0.3
    };

    let increment = waves.frequency;

    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2);
      
      for (let i = 0; i < canvas.width; i++) {
        ctx.lineTo(
          i,
          waves.y + Math.sin(i * waves.length + increment) * waves.amplitude * Math.sin(increment)
        );
      }
      
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, `hsla(${strokeColor.h}, ${strokeColor.s}%, ${strokeColor.l}%, ${strokeColor.a})`);
      gradient.addColorStop(0.5, `hsla(${strokeColor.h + 5}, ${strokeColor.s}%, ${strokeColor.l + 5}%, ${strokeColor.a + 0.1})`);
      gradient.addColorStop(1, `hsla(${strokeColor.h}, ${strokeColor.s}%, ${strokeColor.l}%, ${strokeColor.a})`);
      
      ctx.strokeStyle = gradient;
      ctx.lineWidth = 1;
      ctx.stroke();
      
      increment += waves.frequency;
      requestAnimationFrame(render);
    };

    render();

    const handleResize = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Text carousel state
  const [headerTextIndex, setHeaderTextIndex] = useState(0);
  const [footerTextIndex, setFooterTextIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const headerTextSets = [
    {
      title: "Job search becomes easier with your professional network!",
      description: "Professional connections play a vital role in finding job opportunities."
    },
    {
      title: "Job Search Feels Lonely?",
      description: "Find Support, Connect, Succeed!"
    },
    {
      title: "Sign up!",
      description: "Complete your profile & get e-meets scheduled every week."
    }
  ];

  const footerTextSets = [
    {
      title: "Around 85% of jobs are secured through networking.",
      description: "Building strong networks can boost your chances of getting hired."
    },
    {
      title: "Referred candidates are 4x more likely to be hired.",
      description: "Build connections to unlock new job opportunities!"
    },
    {
      title: "Building your professional network shouldn't be hard.",
      description: "Jobseeker Circle helps you grow your network through one-on-one calls — one connection at a time!"
    }
  ];

  // Cycle through text sets with fade effect
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setHeaderTextIndex((prevIndex) => (prevIndex + 1) % headerTextSets.length);
        setFooterTextIndex((prevIndex) => (prevIndex + 1) % footerTextSets.length);
        setFade(true);
      }, 750); // Half of the transition duration
    }, 8000); // Change text every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setErrorMessage("");
    setMessage("");

    // Basic validation
    if (!email || !password) {
      setErrorMessage("Please fill all required fields");
      return;
    }
    
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long");
      return;
    }
    
    setLoading(true);
    
    try {
      // Make API request to register the user
      const response = await axios.post('https://d18ye91bhcj72x.cloudfront.net/api/auth/register', {
        email,
        password,
        rememberMe
      });

      setMessage("A verification link has been sent to your email address. Please check your inbox to verify your account.");

      // Reset fields
      setEmail("");
      setPassword("");
      
      // Navigate to home after successful signup
      navigate("/");
    } catch (err) {
      console.error(err);
      // Display the specific error message returned by the backend
      if (err.response && err.response.data && err.response.data.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage("Registration failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const getPasswordStrengthColor = () => {
    switch (strength) {
      case 0: return "#e0e0e0";
      case 1: return "#f44336"; // weak - red
      case 2: return "#ff9800"; // medium - orange
      case 3: return "#4caf50"; // strong - green
      default: return "#e0e0e0";
    }
  };

  const getPasswordStrengthLabel = () => {
    switch (strength) {
      case 0: return "";
      case 1: return "Weak";
      case 2: return "Medium";
      case 3: return "Strong";
      default: return "";
    }
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      {/* Left Side - Background with Waves */}
      <Box 
        sx={{ 
          display: { xs: "none", md: "flex" },
          width: "50%", 
          bgcolor: "#51ade5", 
          color: "white", 
          flexDirection: "column",
          justifyContent: "space-between",
          p: 5,
          position: "relative",
          overflow: "hidden"
        }}
      >
        <canvas 
          ref={canvasRef} 
          style={{ 
            position: "absolute",
            inset: 0,
            width: "100%",
            height: "100%",
            opacity: 0.3
          }}
        />
        
        {/* Header Text Carousel */}
        <Box 
          sx={{ 
            position: "relative", 
            zIndex: 1, 
            transition: "opacity 1.5s ease-in-out", 
            opacity: fade ? 1 : 0,
            height: 150 // Set a fixed height to prevent shifting
          }}
        >
          <Typography variant="h3" fontWeight="bold" mb={1} color="white">
            {headerTextSets[headerTextIndex].title}
          </Typography>
          <Typography variant="body1" color="white" sx={{ opacity: 0.9 }}>
            {headerTextSets[headerTextIndex].description}
          </Typography>
        </Box>
        
        {/* Footer Text Carousel */}
        <Box 
          sx={{ 
            position: "relative", 
            zIndex: 1, 
            transition: "opacity 1.5s ease-in-out", 
            opacity: fade ? 1 : 0,
            height: 150 // Set a fixed height to prevent shifting
          }}
        >
          <Typography variant="h4" fontWeight="bold" mb={2} color="white">
            {footerTextSets[footerTextIndex].title}
          </Typography>
          <Typography variant="body1" color="white" sx={{ opacity: 0.9 }} mb={3}>
            {footerTextSets[footerTextIndex].description}
          </Typography>
          
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box sx={{ height: 8, width: 8, borderRadius: "50%", bgcolor: "white", opacity: 0.9 }} />
            <Box sx={{ height: 8, width: 8, borderRadius: "50%", bgcolor: "white", opacity: 0.6 }} />
            <Box sx={{ height: 8, width: 8, borderRadius: "50%", bgcolor: "white", opacity: 0.3 }} />
          </Box>
        </Box>
      </Box>
      
      {/* Right Side - Sign Up Form */}
      <Box 
        sx={{ 
          width: { xs: "100%", md: "50%" }, 
          display: "flex", 
          alignItems: "center", 
          justifyContent: "center", 
          p: 3 
        }}
      >
        <Paper 
          elevation={0} 
          sx={{ 
            width: "100%", 
            maxWidth: 450, 
            p: { xs: 2, sm: 4 } 
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                component="img"
                src="../images/company-logo.png"
                alt="Company Logo"
                sx={{ mr: 1.5, width: 58, height: 58, borderRadius: "50%", objectFit: "cover" }}
              />
              <Typography variant="h6" fontWeight="semibold" color="text.primary">Welcome to Jobseeker Circle !</Typography>
            </Box>
          </Box>
          
          {/* Display success message */}
          {message && (
            <Alert severity="success" sx={{ marginBottom: "10px" }}>
              {message}
            </Alert>
          )}

          {/* Display error message */}
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "10px" }}>
              {errorMessage}
            </Alert>
          )}

          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 4 }}>
            <Button 
              variant="contained" 
              sx={{ width: 120, bgcolor: "#51ade5", "&:hover": { bgcolor: "#4597c9" } }}
            >
              Sign Up
            </Button>
            <Button 
              variant="outlined" 
              sx={{ width: 120, color: "text.secondary", borderColor: "grey.300" }}
              onClick={() => navigate("/login")}
            >
              Sign In
            </Button>
          </Box>
          
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="medium" mb={1} color="text.secondary">
                Email ID
              </Typography>
              <TextField
                fullWidth
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                variant="outlined"
                required
                InputProps={{
                  sx: { height: 48 }
                }}
              />
            </Box>
            
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                <Typography variant="subtitle1" fontWeight="medium" color="text.secondary">
                  Password
                </Typography>
                <Link to="#" style={{ textDecoration: "none" }}>
                  {/* <Typography variant="caption" color="text.disabled">
                    Forgot Password?
                  </Typography> */}
                </Link>
              </Box>
              
              <TextField
                fullWidth
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                variant="outlined"
                required
                InputProps={{
                  sx: { height: 48 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              
              {password.length > 0 && (
                <Box sx={{ mt: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={(strength / 3) * 100}
                      sx={{ 
                        flexGrow: 1, 
                        height: 8, 
                        borderRadius: 4,
                        bgcolor: 'grey.200',
                        '& .MuiLinearProgress-bar': {
                          bgcolor: getPasswordStrengthColor()
                        }
                      }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      {getPasswordStrengthLabel()}
                    </Typography>
                  </Box>
                  
                  <List dense disablePadding>
                    <ListItem disablePadding sx={{ py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        {doesNotContainName ? (
                          <CheckCircle fontSize="small" color="success" />
                        ) : (
                          <Cancel fontSize="small" color="disabled" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="caption" color={doesNotContainName ? "success.main" : "text.disabled"}>
                            Cannot contain your name or email address
                          </Typography>
                        }
                      />
                    </ListItem>
                    
                    <ListItem disablePadding sx={{ py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        {hasMinLength ? (
                          <CheckCircle fontSize="small" color="success" />
                        ) : (
                          <Cancel fontSize="small" color="disabled" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="caption" color={hasMinLength ? "success.main" : "text.disabled"}>
                            At least 8 characters
                          </Typography>
                        }
                      />
                    </ListItem>
                    
                    <ListItem disablePadding sx={{ py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        {hasSymbolOrNumber ? (
                          <CheckCircle fontSize="small" color="success" />
                        ) : (
                          <Cancel fontSize="small" color="disabled" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="caption" color={hasSymbolOrNumber ? "success.main" : "text.disabled"}>
                            Contains a number or symbol
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              )}
            </Box>
            
            {/* Create Account Button */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ 
                height: 48, 
                bgcolor: "#51ade5",
                '&:hover': {
                  bgcolor: "#4597c9"
                }
              }}
            >
              {loading ? "Creating Account..." : "Create Account"}
            </Button>

            {/* Horizontal line with "or" */}
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
              <hr style={{ flex: 1 }} />
              <span style={{ margin: '0 10px', color: '#888' }}>or</span>
              <hr style={{ flex: 1 }} />
            </div>

            {/* LinkedIn Login Button */}
            <Button
              variant="contained"
              fullWidth
              color="primary"
              startIcon={<LinkedInIcon />}
              onClick={() => {
                // Redirect to LinkedIn OAuth
                window.location.href = 'https://d18ye91bhcj72x.cloudfront.net/api/auth/linkedin';
              }}
              style={{ backgroundColor: '#0077B5', color: '#fff', textTransform: 'none' }}
            >
              Continue with LinkedIn
            </Button>
          </form>
          
          <Typography variant="caption" color="text.disabled" align="center" sx={{ display: "block", mt: 4 }}>
            By signing up, I accept Jobseeker Circle's{" "}
            <a href="https://www.jobseekercircle.com/terms-and-conditions/" style={{ color: "#51ade5" }} target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>{" "}
            &{" "}
            <a href="https://www.jobseekercircle.com/privacy-policy-2/" style={{ color: "#51ade5" }} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>.
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default Register;

