import React from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const Filters = ({
  search,
  setSearch,
  roleFilter,
  setRoleFilter,
  verifiedFilter,
  setVerifiedFilter,
  cityFilter,
  setCityFilter,
  currentRoleFilter, // New filter
  setCurrentRoleFilter,
}) => {
  return (
    <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
      <TextField label="Search Users" variant="outlined" fullWidth value={search} onChange={(e) => setSearch(e.target.value)} />

      <FormControl fullWidth>
        <InputLabel>Role</InputLabel>
        <Select value={roleFilter} onChange={(e) => setRoleFilter(e.target.value)}>
          <MenuItem value="">All</MenuItem>
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="superadmin">Superadmin</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>Verified</InputLabel>
        <Select value={verifiedFilter} onChange={(e) => setVerifiedFilter(e.target.value)}>
          <MenuItem value="">All</MenuItem>
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>

      <TextField label="City" variant="outlined" fullWidth value={cityFilter} onChange={(e) => setCityFilter(e.target.value)} />

      <TextField label="Current Role" variant="outlined" fullWidth value={currentRoleFilter} onChange={(e) => setCurrentRoleFilter(e.target.value)} />
    </div>
  );
};

export default Filters;




