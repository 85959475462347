
// import React, { useEffect, useState } from "react";
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
//   Paper, Select, MenuItem, IconButton, Avatar, TablePagination, Typography, Box
// } from "@mui/material";
// import { Delete } from "@mui/icons-material";
// import axios from "axios";
// import ExportButton from "./ExportButton";
// import Filter from "./Filters";

// const UsersTable = () => {
//   const [users, setUsers] = useState([]);
//   const [search, setSearch] = useState("");
//   const [roleFilter, setRoleFilter] = useState("");
//   const [verifiedFilter, setVerifiedFilter] = useState("");
//   const [cityFilter, setCityFilter] = useState("");
//   const [currentRoleFilter, setCurrentRoleFilter] = useState("");
//   const [page, setPage] = useState(0);
//   const rowsPerPage = 20; // 20 users per page

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const getAuthToken = () => localStorage.getItem("adminAuthToken");

//   const fetchUsers = async () => {
//     try {
//       const response = await axios.get("http://localhost:5000/api/admin/users", {
//         headers: { Authorization: `Bearer ${getAuthToken()}` },
//       });
//       setUsers(response.data);
//     } catch (error) {
//       console.error("Error fetching users", error);
//     }
//   };

//   const handleRoleChange = async (userId, newRole) => {
//     try {
//       await axios.put(
//         `http://localhost:5000/api/admin/users/${userId}/role`,
//         { role: newRole },
//         { headers: { Authorization: `Bearer ${getAuthToken()}` } }
//       );
//       setUsers((prevUsers) =>
//         prevUsers.map((user) =>
//           user._id === userId ? { ...user, role: newRole } : user
//         )
//       );
//     } catch (error) {
//       console.error("Error updating role", error);
//     }
//   };

//   const handleDelete = async (userId) => {
//     try {
//       await axios.delete(`http://localhost:5000/api/admin/users/${userId}`, {
//         headers: { Authorization: `Bearer ${getAuthToken()}` },
//       });
//       setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
//     } catch (error) {
//       console.error("Error deleting user", error);
//     }
//   };

//   const filteredUsers = users.filter((user) => {
//     return (
//       (search === "" ||
//         (user.firstName?.toLowerCase().includes(search.toLowerCase()) || "") ||
//         (user.lastName?.toLowerCase().includes(search.toLowerCase()) || "") ||
//         (user.email?.toLowerCase().includes(search.toLowerCase()) || "")) &&
//       (roleFilter === "" || user.role === roleFilter) &&
//       (verifiedFilter === "" || String(user.isVerified) === verifiedFilter) &&
//       (cityFilter === "" || (user.city || "").toLowerCase().includes(cityFilter.toLowerCase())) &&
//       (currentRoleFilter === "" || (user.currentRole || "").toLowerCase().includes(currentRoleFilter.toLowerCase()))
//     );
//   });

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   return (
//     <Box sx={{ mt: 3 }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>All Users</Typography>

//       {/* Filters */}
//       <Filter
//         search={search}
//         setSearch={setSearch}
//         roleFilter={roleFilter}
//         setRoleFilter={setRoleFilter}
//         verifiedFilter={verifiedFilter}
//         setVerifiedFilter={setVerifiedFilter}
//         cityFilter={cityFilter}
//         setCityFilter={setCityFilter}
//         currentRoleFilter={currentRoleFilter}
//         setCurrentRoleFilter={setCurrentRoleFilter}
//       />

//       {/* Export Button */}
//       <ExportButton />

//       {/* Table */}
//       <TableContainer component={Paper} sx={{ mt: 2 }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Profile</TableCell>
//               <TableCell>Name</TableCell>
//               <TableCell>Email</TableCell>
//               <TableCell>Current Role</TableCell>
//               <TableCell>City</TableCell>
//               <TableCell>Organization</TableCell>
//               <TableCell>Skills</TableCell>
//               <TableCell>Availability</TableCell>
//               <TableCell>Verified</TableCell>
//               <TableCell>Role</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>

//           <TableBody>
//             {filteredUsers
//               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map((user) => (
//                 <TableRow key={user._id}>
//                   <TableCell>
//                     <Avatar src={user.profileImage?.url} alt={user.firstName} />
//                   </TableCell>
//                   <TableCell>{user.firstName} {user.lastName}</TableCell>
//                   <TableCell>{user.email}</TableCell>
//                   <TableCell>{user.currentRole || "N/A"}</TableCell>
//                   <TableCell>{user.city}</TableCell>
//                   <TableCell>{user.organization || "N/A"}</TableCell>
//                   <TableCell>{user.skills?.join(", ") || "N/A"}</TableCell>
//                   <TableCell>{user.availability?.join(", ") || "N/A"}</TableCell>
//                   <TableCell>{user.isVerified ? "Yes" : "No"}</TableCell>
//                   <TableCell>
//                     <Select
//                       value={user.role}
//                       onChange={(e) => handleRoleChange(user._id, e.target.value)}
//                       size="small"
//                     >
//                       <MenuItem value="user">User</MenuItem>
//                       <MenuItem value="admin">Admin</MenuItem>
//                       <MenuItem value="superadmin">Superadmin</MenuItem>
//                     </Select>
//                   </TableCell>
//                   <TableCell>
//                     <IconButton onClick={() => handleDelete(user._id)} color="error">
//                       <Delete />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Pagination */}
//       <TablePagination
//         component="div"
//         count={filteredUsers.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPageOptions={[20]} // Fixed at 20 per page
//       />
//     </Box>
//   );
// };

// export default UsersTable;



import React, { useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Select, MenuItem, IconButton, Avatar, TablePagination, Typography, Box
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import axios from "axios";
import ExportButton from "./ExportButton";
import Filter from "./Filters";

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [verifiedFilter, setVerifiedFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [currentRoleFilter, setCurrentRoleFilter] = useState("");
  const [organizationFilter, setOrganizationFilter] = useState("");
  const [skillsFilter, setSkillsFilter] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 20; // 20 users per page

  useEffect(() => {
    fetchUsers();
  }, []);

  const getAuthToken = () => localStorage.getItem("adminAuthToken");

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://d18ye91bhcj72x.cloudfront.net/api/admin/users", {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      });
      setUsers(response.data || []); // Ensure users is always an array
    } catch (error) {
      console.error("Error fetching users", error);
      setUsers([]); // Avoid setting null
    }
  };
  

  const handleRoleChange = async (userId, newRole) => {
    try {
      await axios.put(
        `https://d18ye91bhcj72x.cloudfront.net/api/admin/users/${userId}/role`,
        { role: newRole },
        { headers: { Authorization: `Bearer ${getAuthToken()}` } }
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.error("Error updating role", error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`https://d18ye91bhcj72x.cloudfront.net/api/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      });
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
    } catch (error) {
      console.error("Error deleting user", error);
    }
  };

  const filteredUsers = users.filter((user) => {
    return (
      (search === "" ||
        (user.firstName?.toLowerCase().includes(search.toLowerCase()) || "") ||
        (user.lastName?.toLowerCase().includes(search.toLowerCase()) || "") ||
        (user.email?.toLowerCase().includes(search.toLowerCase()) || "")) &&
      (roleFilter === "" || user.role === roleFilter) &&
      (verifiedFilter === "" || String(user.isVerified) === verifiedFilter) &&
      (cityFilter === "" || (user.city || "").toLowerCase().includes(cityFilter.toLowerCase())) &&
      (currentRoleFilter === "" || (user.currentRole || "").toLowerCase().includes(currentRoleFilter.toLowerCase())) &&
      (organizationFilter === "" || (user.organization || "").toLowerCase().includes(organizationFilter.toLowerCase())) &&
      (skillsFilter === "" || (user.skills?.join(", ") || "").toLowerCase().includes(skillsFilter.toLowerCase()))
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>All Users</Typography>

      {/* Filters */}
      <Filter
        search={search}
        setSearch={setSearch}
        roleFilter={roleFilter}
        setRoleFilter={setRoleFilter}
        verifiedFilter={verifiedFilter}
        setVerifiedFilter={setVerifiedFilter}
        cityFilter={cityFilter}
        setCityFilter={setCityFilter}
        currentRoleFilter={currentRoleFilter}
        setCurrentRoleFilter={setCurrentRoleFilter}
        organizationFilter={organizationFilter}
        setOrganizationFilter={setOrganizationFilter}
        skillsFilter={skillsFilter}
        setSkillsFilter={setSkillsFilter}
      />

      {/* Export Button */}
      <ExportButton users={filteredUsers} />

      {/* Table */}
      <TableContainer component={Paper} sx={{ mt: 2, overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Profile</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Current Role</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Skills</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
          {filteredUsers
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  .map((user) => user ? (
    <TableRow key={user._id}>
      <TableCell>
        <Avatar src={user?.profileImage?.url} alt={user?.firstName || "User"} />
      </TableCell>
      <TableCell>{user?.firstName || "N/A"} {user?.lastName || ""}</TableCell>
      <TableCell>{user?.email || "N/A"}</TableCell>
      <TableCell>{user?.currentRole || "N/A"}</TableCell>
      <TableCell>{user?.city || "N/A"}</TableCell>
      <TableCell>{user?.organization || "N/A"}</TableCell>
      <TableCell>{user?.skills?.join(", ") || "N/A"}</TableCell>
      <TableCell>{user?.availability?.join(", ") || "N/A"}</TableCell>
      <TableCell>{user?.isVerified ? "Yes" : "No"}</TableCell>
      <TableCell>
        <Select
          value={user?.role || "user"}
          onChange={(e) => handleRoleChange(user._id, e.target.value)}
          size="small"
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="superadmin">Superadmin</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleDelete(user._id)} color="error">
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  ) : null)}

          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[20]} // Fixed at 20 per page
      />
    </Box>
  );
};

export default UsersTable;
