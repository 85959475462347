import React from "react";
import { Button } from "@mui/material";
import axios from "axios";
import FileSaver from "file-saver";

const ExportButton = () => {
  const handleExport = async () => {
    try {
      const response = await axios.get("https://d18ye91bhcj72x.cloudfront.net/api/admin/users/export", {
        headers: { Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}` },
        responseType: "blob",
      });
      FileSaver.saveAs(response.data, "users.xlsx");
    } catch (error) {
      console.error("Error exporting users", error);
    }
  };

  return <Button onClick={handleExport} variant="contained">Download Users</Button>;
};

export default ExportButton;
