import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  OutlinedInput,
  CircularProgress,
  Avatar,
  Card,
  CardContent,
  IconButton,
  FormLabel
} from '@mui/material';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  PhotoCamera,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Edit as EditIcon
} from '@mui/icons-material';

const ProfileSettings = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    city: '',
    currentRole: '',
    organization: '',
    skills: [],
    bio: '',
    availability: [],
    profileImage: null,
  });
  const [customSkill, setCustomSkill] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigate();

  //const commonSkills = ['JavaScript', 'Python', 'Java', 'React', 'Node.js', 'SQL', 'C++'];
  const commonSkills = ["Project Management",
    "Software Development",
    "UI/UX Design",
    "Quality Assurance",
    "Business Analysis",
    "Data Analysis",
    "Agile Methodologies",
    "Team Leadership",
    "Communication Skills",
    "Problem-Solving",
    "Stakeholder Engagement",
    "Strategic Planning"]

  const availabilityOptions = ['Thursday 7:30 PM AEST', 'Thursday 8:00 PM AEST', 'Friday 12:00 PM AEST', 'Friday 12:30 PM AEST'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleOrganizationChange = (organization) => {
  //   setFormData({ ...formData, organization });
  // };
  const handleOrganizationChange = (organization) => {
    setFormData({ ...formData, organization: organization?.toString() || '' });
  };
  

  const handleSkillChange = (event) => {
    const value = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setFormData({ ...formData, skills: value });
  };

  const handleAddCustomSkill = () => {
    if (customSkill.trim()) {
      setFormData((prevData) => ({ ...prevData, skills: [...prevData.skills, customSkill.trim()] }));
      setCustomSkill('');
    }
  };

  const handleAvailabilityChange = (event) => {
    const value = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setFormData({ ...formData, availability: value });
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, profileImage: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setLoading(true);

    const bioWords = formData.bio.trim().split(/\s+/);
    if (bioWords.length < 10) {
      setError('Bio must contain at least 10 words.');
      setLoading(false);
      return;
    }

    
  const getAuthToken = () => {
    return localStorage.getItem("adminAuthToken") || localStorage.getItem("authToken") || "";
  };
  
      const token = getAuthToken().trim();
      if (!token) {
        console.error("No auth token found!");
        setError("Authentication error. Please log in again.");
        setIsFetching(false);
        return;
      }
    const formDataToSend = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'profileImage' && value) {
        formDataToSend.append(key, value);
      } else if (Array.isArray(value)) {
        // Append arrays directly for proper handling
        value.forEach((item) => formDataToSend.append(`${key}[]`, item));
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      await axios.put('https://d18ye91bhcj72x.cloudfront.net/api/profile/update-profile', formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setSuccessMessage('Profile updated successfully.');
      setIsEditable(false);
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.msg || 'An error occurred while updating the profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

const getAuthToken = () => {
    return localStorage.getItem("adminAuthToken") || localStorage.getItem("authToken") || "";
  };
  useEffect(() => {
    
    const fetchProfileData = async () => {
      setIsFetching(true);
      const token = getAuthToken().trim();
      if (!token) {
        console.error("No auth token found!");
        setError("Authentication error. Please log in again.");
        setIsFetching(false);
        return;
      }
      

      try {
        const response = await axios.get('https://d18ye91bhcj72x.cloudfront.net/api/profile/get-profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const profileData = response.data;

        setFormData({
          ...profileData,
          skills: Array.isArray(profileData.skills) ? profileData.skills : [],
          availability: Array.isArray(profileData.availability) ? profileData.availability : [],
        });
        if (profileData.profileImage) {
          console.log("Image URL:", profileData.profileImage);
          setImagePreview(profileData.profileImage.url || profileData.profileImage);
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch profile data.');
      } finally {
        setIsFetching(false);
      }
    };


    fetchProfileData();
  }, []);

  if (isFetching) {
    return <CircularProgress />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100vw" }}>
    <Navbar />
    <div style={{ 
        flex: 1, 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems:"center", 
        width: "100%" 
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: '#fff',
          width: { xs: '70%', sm: '60%', md: '50%' },
          padding: '30px',
          borderRadius: '8px',
          boxShadow: 3,
          gap: 3,
          marginY: "30px"
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
          Edit Your Profile
        </Typography>

        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}



        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" sx={{ width: { xs: '100%', sm: '28rem' }, gap: 2 }}>
            <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
              <Avatar
                src={imagePreview}
                alt="Profile Image"
                sx={{ width: 150, height: 150 }}
              />
              {isEditable && (
                <IconButton color="primary" component="label">
                  <PhotoCamera />
                  <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                </IconButton>
              )}
            </Box>
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              disabled={!isEditable}
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              disabled={!isEditable}
            />

            
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <FormLabel sx={{ marginBottom: '5px', color: 'rgba(0, 0, 0, 0.6)' }}>Phone Number</FormLabel>
              <PhoneInput
                country="au"
                value={formData.phone}
                onChange={handlePhoneChange}
                inputStyle={{ width: '100%' }}
                disabled={!isEditable}
              />
            </FormControl>


            <TextField
  label="Organization"
  name="organization"
  value={formData.organization || ''}
  onChange={(e) => handleOrganizationChange(e.target.value)}
  required
  disabled={!isEditable}
/>


            <TextField
              label="City"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              required
              disabled={!isEditable}
            />
            <FormControl fullWidth>
              <InputLabel>Current Role</InputLabel>
              <Select
                name="currentRole"
                value={formData.currentRole || ''}
                onChange={handleInputChange}
                input={<OutlinedInput label="Current Role" />}
                required
                disabled={!isEditable}
              >
                {['Project Manager', 'Developer', 'Designer', 'QA Tester', 'Business Analyst', 'IT Service Delivery Manager ', 'Market Research Analyst', 'Head of solutions architecture', 'Service Delivery Manager ', 'Test Specialist', 'Consultant', 'Software Project Manager', 'Sustainability Consultant', 'Senior Data Engineer', 'Currently looking for job', 'Customer Scientist', 'Student', 'Counsellor ', 'Scrum master', 'Senior IT Project Delivery Lead', 'Senior manager', 'NA', 'Data Analyst'].sort().map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Skills</InputLabel>
              <Select
                multiple
                value={formData.skills || []}
                onChange={handleSkillChange}
                input={<OutlinedInput label="Skills" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                disabled={!isEditable}
              >
                {commonSkills.map((skill) => (
                  <MenuItem key={skill} value={skill}>
                    {skill}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isEditable && (
              <TextField
                label="Add Custom Skill"
                value={customSkill}
                onChange={(e) => setCustomSkill(e.target.value)}
                onBlur={handleAddCustomSkill}
              />
            )}
            <TextField
              label="Bio"
              name="bio"
              value={formData.bio}
              onChange={handleInputChange}
              multiline
              rows={4}
              required
              disabled={!isEditable}
            />
            <FormControl fullWidth>
              <InputLabel>Availability</InputLabel>
              <Select
                multiple
                value={formData.availability || []}
                onChange={handleAvailabilityChange}
                input={<OutlinedInput label="Availability" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                disabled={!isEditable}
              >
                {availabilityOptions.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              onClick={() => setIsEditable(!isEditable)}
              sx={{ alignSelf: 'center' }}
            >
              {isEditable ? 'Cancel Edit' : 'Edit'}
            </Button>
          </Box>

          {isEditable && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
              mt={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                startIcon={<SaveIcon />}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Save Changes'}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setIsEditable(false)}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            </Box>
          )}
        </form>
      </Box>
      </div>
      <Footer />
    </div>
  );
};

export default ProfileSettings;


// import React, { useState, useEffect } from "react";
// import {
//   TextField, Button, Avatar, Typography, CircularProgress, Box, Grid, MenuItem,
// } from "@mui/material";
// import axios from "axios";

// const ProfileSettings = () => {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     bio: "",
//     city: "",
//     currentRole: "",
//     organization: "",
//     skills: [],
//     availability: [],
//     profileImage: null,
//   });

//   const [imagePreview, setImagePreview] = useState(null);
//   const [isFetching, setIsFetching] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [successMessage, setSuccessMessage] = useState("");
//   const [isEditable, setIsEditable] = useState(false);

//   const getAuthToken = () => {
//     return localStorage.getItem("adminAuthToken") || localStorage.getItem("authToken") || "";
//   };

//   useEffect(() => {
//     const fetchProfileData = async () => {
//       setIsFetching(true);
//       setError("");

//       const token = getAuthToken().trim();
//       if (!token) {
//         console.error("No auth token found!");
//         setError("Authentication error. Please log in again.");
//         setIsFetching(false);
//         return;
//       }

//       try {
//         const response = await axios.get("https://d18ye91bhcj72x.cloudfront.net/api/profile/get-profile", {
//           headers: { Authorization: `Bearer ${token}` },
//         });

//         const profileData = response.data;
//         setFormData({
//           ...profileData,
//           skills: Array.isArray(profileData.skills) ? profileData.skills : [],
//           availability: Array.isArray(profileData.availability) ? profileData.availability : [],
//         });

//         if (profileData.profileImage) {
//           setImagePreview(profileData.profileImage.url || profileData.profileImage);
//         }
//       } catch (err) {
//         console.error("Error fetching profile:", err);
//         setError("Failed to fetch profile data.");
//       } finally {
//         setIsFetching(false);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFormData((prev) => ({ ...prev, profileImage: file }));

//     if (file) {
//       const reader = new FileReader();
//       reader.onload = (event) => setImagePreview(event.target.result);
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError("");
//     setSuccessMessage("");
//     setLoading(true);

//     const bioWords = formData.bio.trim().split(/\s+/);
//     if (bioWords.length < 10) {
//       setError("Bio must contain at least 10 words.");
//       setLoading(false);
//       return;
//     }

//     const token = getAuthToken().trim();
//     if (!token) {
//       setError("Authentication error. Please log in again.");
//       setLoading(false);
//       return;
//     }

//     const formDataToSend = new FormData();
//     Object.entries(formData).forEach(([key, value]) => {
//       if (key === "profileImage" && value) {
//         formDataToSend.append(key, value);
//       } else if (Array.isArray(value)) {
//         value.forEach((item) => formDataToSend.append(`${key}[]`, item));
//       } else {
//         formDataToSend.append(key, value);
//       }
//     });

//     try {
//       await axios.put("https://d18ye91bhcj72x.cloudfront.net/api/profile/update-profile", formDataToSend, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       setSuccessMessage("Profile updated successfully.");
//       setIsEditable(false);
//     } catch (err) {
//       console.error("Error updating profile:", err);
//       setError(err.response?.data?.msg || "An error occurred while updating the profile. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box sx={{ p: 3, maxWidth: 600, mx: "auto" }}>
//       <Typography variant="h5" sx={{ mb: 2 }}>Profile Settings</Typography>

//       {isFetching ? (
//         <CircularProgress />
//       ) : (
//         <form onSubmit={handleSubmit}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
//               <Avatar src={imagePreview} sx={{ width: 80, height: 80 }} />
//             </Grid>
//             <Grid item xs={12}>
//               <input type="file" accept="image/*" onChange={handleFileChange} />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 fullWidth
//                 label="First Name"
//                 name="firstName"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 disabled={!isEditable}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 fullWidth
//                 label="Last Name"
//                 name="lastName"
//                 value={formData.lastName}
//                 onChange={handleChange}
//                 disabled={!isEditable}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Email"
//                 name="email"
//                 value={formData.email}
//                 disabled
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="City"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//                 disabled={!isEditable}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Current Role"
//                 name="currentRole"
//                 value={formData.currentRole}
//                 onChange={handleChange}
//                 disabled={!isEditable}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Organization"
//                 name="organization"
//                 value={formData.organization}
//                 onChange={handleChange}
//                 disabled={!isEditable}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 label="Bio"
//                 name="bio"
//                 value={formData.bio}
//                 onChange={handleChange}
//                 disabled={!isEditable}
//                 multiline
//                 rows={3}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 select
//                 label="Availability"
//                 name="availability"
//                 value={formData.availability}
//                 onChange={handleChange}
//                 disabled={!isEditable}
//                 SelectProps={{ multiple: true }}
//               >
//                 <MenuItem value="Full-Time">Full-Time</MenuItem>
//                 <MenuItem value="Part-Time">Part-Time</MenuItem>
//                 <MenuItem value="Freelance">Freelance</MenuItem>
//               </TextField>
//             </Grid>

//             {error && (
//               <Grid item xs={12}>
//                 <Typography color="error">{error}</Typography>
//               </Grid>
//             )}
//             {successMessage && (
//               <Grid item xs={12}>
//                 <Typography color="success">{successMessage}</Typography>
//               </Grid>
//             )}
//             <Grid item xs={12}>
//               <Button variant="contained" color="primary" fullWidth onClick={() => setIsEditable(true)}>
//                 Edit Profile
//               </Button>
//             </Grid>
//             {isEditable && (
//               <Grid item xs={12}>
//                 <Button type="submit" variant="contained" color="success" fullWidth disabled={loading}>
//                   {loading ? <CircularProgress size={24} /> : "Save Changes"}
//                 </Button>
//               </Grid>
//             )}
//           </Grid>
//         </form>
//       )}
//     </Box>
//   );
// };

// export default ProfileSettings;
