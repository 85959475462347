



import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";

const AdminNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("adminAuthToken");
    navigate("/admin-login");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClick = () => {
    navigate("/update-profile");
    setAnchorEl(null);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        color: "#333",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Toolbar sx={{ px: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          {/* Left Side - Logo */}
          <Box display="flex" alignItems="center">
            <Box
              component="img"
              src={"../images/company-logo.png"}
              alt="Company Logo"
              sx={{ height: 100, mr: 2, padding: "10px", cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", display: { xs: "none", md: "block" }, cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            >
              JobSeeker Circle
            </Typography>
          </Box>

          {/* Right Side - Profile Icon */}
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={handleProfileMenuOpen}>
              <AccountCircle sx={{ color: "#333", fontSize: 40 }} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose}>
              <MenuItem onClick={handleProfileClick}>Profile Settings</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>

          {/* Hamburger Menu for Mobile Screens */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Drawer for Mobile Navigation */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <Box display="flex" alignItems="center" p={2}>
            <Box
              component="img"
              src={"../images/company-logo.png"}
              alt="Company Logo"
              sx={{ height: 50, mr: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Admin Panel
            </Typography>
          </Box>
          <Divider />
          <List>
            <ListItem button onClick={handleProfileClick}>
              <ListItemText primary="Profile Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default AdminNavbar;





// import React, { useState } from "react";
// import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { useNavigate } from "react-router-dom";

// const AdminNavbar = () => {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     localStorage.removeItem("adminAuthToken");
//     navigate("/admin-login");
//   };

//   return (
//     <Box>
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(true)}>
//             <MenuIcon />
//           </IconButton>
//           <Box sx={{ flexGrow: 1, textAlign: "center" }}>Admin Dashboard</Box>
//           <IconButton color="inherit" onClick={handleLogout}>
//             <AccountCircleIcon />
//           </IconButton>
//         </Toolbar>
//       </AppBar>

//       <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
//         <List>
//           <ListItem button onClick={() => navigate("/admin/appointments")}>
//             <ListItemText primary="Appointments" />
//           </ListItem>
//           <ListItem button onClick={() => navigate("/admin/users")}>
//             <ListItemText primary="Users" />
//           </ListItem>
//         </List>
//       </Drawer>
//     </Box>
//   );
// };

// export default AdminNavbar;
