import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, Typography, Alert, IconButton, InputAdornment } from "@mui/material";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.fromVerification) {
      setMessage("Email verified successfully. Please log in.");
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setErrorMessage("");
    setMessage("");

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (email === "" || password === "") {
      setErrorMessage("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(`https://d18ye91bhcj72x.cloudfront.net/api/admin/login`, {
        email,
        password,
      });

      localStorage.setItem("adminAuthToken", response.data.token);
      
      setEmail("");
      setPassword("");

      setMessage("Admin login successful.");
      navigate("/admin-dashboard"); // Redirect to admin panel
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#fff",
        width: { xs: "90%", sm: "60%", md: "43%", xl: "30%" },
        margin: { xs: "0 15px" },
      }}
      padding="20px"
      borderRadius="5%"
    >
      <Box textAlign="center">
        <Box
          component="img"
          alt="logo"
          src="../images/company-logo.png"
          sx={{ objectFit: "cover", height: "130px", width: "130px" }}
        />
        <Typography variant="h5" padding="10px" textAlign="center">
          ADMIN LOGIN
        </Typography>
      </Box>

      {message && <Alert severity="success" sx={{ marginBottom: "10px" }}>{message}</Alert>}
      {errorMessage && <Alert severity="error" sx={{ marginBottom: "10px" }}>{errorMessage}</Alert>}

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" sx={{ width: { xs: "18rem", sm: "25rem" } }}>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            sx={{ margin: "7px 0" }}
            label="Admin Email"
            variant="outlined"
            type="email"
            required
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            sx={{ margin: "7px 0" }}
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            sx={{ margin: "6px 0", backgroundColor: "#1cdb35" }}
            variant="contained"
          >
            Admin Log In
          </Button>
        </Box>
      </form>
      <Typography
        variant="body2"
        sx={{ marginTop: "10px", cursor: "pointer", color: "#1976d2" }}
        onClick={() => navigate("/forgot-password")}
      >
        Forgot Password?
      </Typography>
    </Box>
  );
};

export default AdminLogin;
